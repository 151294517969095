import Web3 from "web3";
import {ethers} from 'ethers';
import big from "big.js";
// DAI合约的通用ERC-20 ABI和地址
const DAI_ABI = [
  {
    constant: true,
    inputs: [{name: "_owner", type: "address"}],
    name: "balanceOf",
    outputs: [{name: "balance", type: "uint256"}],
    type: "function",
  },
];
const DAI_CONTRACT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955";

const web3 = new Web3(window.ethereum || "https://bsc-rpc.com");

async function connectWallet() {
  try {
    await window.ethereum.enable();
    const accounts = await web3.eth.getAccounts();
    console.log(accounts, 'accountsaccountsaccountsaccounts')
    return accounts.length > 0 ? accounts[0] : null;

    // var provider = new ethers.providers.Web3Provider(window.ethereum); // 这里使用ethers提供者而不是原生的web3
    // const accounts = await provider.listAccounts();
    //      var CurrentAccount=accounts[0]

    //     return CurrentAccount
  } catch (error) {
    console.error("Error connecting wallet:", error);
    return null;
  }
}

async function getDaiBalance(address) {
  try {
    // const daiContract = new web3.eth.Contract(DAI_ABI, DAI_CONTRACT_ADDRESS);
    // const balanceWei = await daiContract.methods.balanceOf(address).call();
    // console.log(balanceWei,'balanceWeibalanceWeibalanceWei')
    // return web3.utils.fromWei(balanceWei, "ether");


    const provider = new ethers.providers.Web3Provider(window.ethereum);
    console.log(provider, '111111111111111111111111111')
    const contract = new ethers.Contract(DAI_CONTRACT_ADDRESS, DAI_ABI, provider);
    const balance = await contract.balanceOf(address)
    console.log(balance.toString(), 'getDaiBalancegetDaiBalancegetDaiBalance')
    var datas = big(balance.toString())
      .div(10 ** 18)
      .toFixed();
    return datas
  } catch (error) {
    console.error("Error fetching DAI balance:", error);
    return null;
  }
}

// 获取 MATIC 余额
async function getMaticBalance(accountAddress) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // 获取账户余额
    const balanceWei = await provider.getBalance(accountAddress);

    // 将余额从 Wei 转换为 MATIC
    const balanceMatic = ethers.utils.formatEther(balanceWei);

    console.log(`MATIC 余额：${balanceMatic}`);
    return balanceMatic
  } catch (error) {
    console.error('获取余额时发生错误:', error);
    return null;
  }
}

export default {
  connectWallet,
  getDaiBalance,
  getMaticBalance
};
