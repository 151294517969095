<template>
    <div class="Private">
      <div class="coinInfomation">
        <img :src="logo">     
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.3)"/>
      <div class="PrivateInfo" v-html="$t('network.law2')">
      </div>
  
  
      <div class="PrivateInfo"></div>
  
      <Divider style="background: rgba(142, 200, 243, 0.3)"/>
     
      <div class="back">
        <button @click="agree" style="margin-right: 10px;">{{$t("stake.agree")}}</button>
        <button @click="$router.go(-1)">{{$t("stake.exit")}}</button>
      </div>
    </div>
  </template>
  
  <style lang="less" scoped>
  .bStyle {
    width: 100%;
    height: 30%;
    margin-bottom: 20px;
  }
  
  .Private {
    .back {
      button {
        width: 70px;
        height: 40px;
        line-height: 37px;
        // background: rgb(0, 0, 0);
        color: rgb(19, 1, 1);
        border-radius: 5px;
        font-size: 14px;
        font-weight: bolder;
        border: 1px solid #1a41ce;
        outline: none;
        cursor: pointer;
        background: url('../assets/img/bgs.svg') no-repeat center center/cover;
      }
    }
  
    .ContactMedia {
      .tips {
        font-size: 15px;
        color: rgb(176, 180, 180);
        font-weight: bold;
      }
  
      .media {
        margin-top: 15px;
  
  
        ul {
          display: flex;
          list-style-type: none;
  
          li {
            margin-right: 10px;
  
            img {
              width: 23px;
              height: 23px;
            }
          }
        }
      }
    }
  
    .PrivateInfo {
      color: aliceblue;
    }
  
    .coinInfomation {
      img {
        width: 40px;
        height: 40px;
      }
  
      h1 {
        padding-top: 20px;
      }
    }
  
    padding: 0;
    margin: 0;
    background-color: black;
    width: 100vw;
    height: auto;
    padding: 20px;
    line-height: 23px;
  }
  </style>
  <script>
  import logo from '@/assets/logo.svg'
  import evwhite from '@/assets/img/evwhite.svg'
  import Facebook from '@/assets/img/t.svg'
  import discord from '@/assets/img/ds.svg'
  import github from '@/assets/img/git.svg'
  import twiter from '@/assets/img/tw.svg'
  import stylea from '@/assets/img/stylea.png'
  
  export default {
    data() {
      return {
        isButtonDisabled: true,
        evwhite: evwhite,
        daiValue: 0,
        daiOwnMoney: 1200.03,
        twiter: twiter,
        github: github,
        discord: discord,
        fackbook: Facebook,
        stylea: stylea,
        logo: logo
  
      };
    },
    computed: {},
    methods: {
    agree() {
      // 返回上一个页面，并传递同意状态
      this.$router.push({ path: '/EvStake', query: { agreed: true } });
    }
  }
  
  };
  </script>
  