<template>
  <div class="stakewait">
    <!-- notification start -->
    <!-- <div class="marquee">
      <Marquee :duration="15">{{$t("burn.flashloan")}}</Marquee>
    </div> -->

    <!-- <div v-if="timershow">
      <div class="nowNumber" v-if="finishshow">{{ $t("ido.end1") }}</div> -->
      <!-- <div class="nowNumber" v-if="finishshow == false">下次可启动倒计时</div> -->
      <!-- <div class="endTime" v-if="finishshow == false">{{ countdown }}</div> -->
    <!-- </div> -->
    <!-- <div v-else> -->
      <!-- <div class="nowNumber">下次可启动倒计时</div>
      <div class="endTime">{{ countdown }}</div> -->
    <!-- </div> --> 
    <!-- notification end -->

    <div class="coinInfomation" style="background-color: rgba(142, 200, 243, 0.0);">
            <div class="coinImg"><img :src="FG1" class="dailogo" /></div>
            <div class="coinInfo" style="color: #fff;">
              {{ $t("menu.Flashloan") }}
            </div>  
      </div>  




    <div class="ownwalletInfo">
      <div class="ownTips">Swift:</div>
      <div class="ownMoney" style="display: flex;align-items: center;">
        {{ formattedDaiOwnMoney }}<img class="daimi" :src="swap_swfit"/>
      </div>
    </div>


    <Divider style="background: rgba(142, 200, 243, 0.5)"/>
    <div class="infoContent">
      <div class="infoImg"> <img src="../assets/img/info.svg" alt=""></div>
      <div class="infoText">{{ $t("flashloans.tips") }}</div>
    </div>

   

    <!-- <div class="ownwalletInfo">
        <div class="ownTips">Matic:</div>
        <div class="ownMoney">
            {{ getMaticBalancevalue | fildecimals }}<img class="swap_falcon" :src="swap_matic" />
        </div>
    </div> -->
    <div class="inputInfo">
      <input v-model.number="daiValue" disabled type="number" class="inputStyle" @input="validateAmount"/>
      <!-- <button @click="(daiValue = formattedDaiOwnMoney), validateAmount()" class="tbnStyle ripple">
          {{ $t("network.all") }}
      </button> -->
    </div>



    <!-- <div class="autoProcess">
        <div class="a1">
            <input type="checkbox" checked="checked">
        </div>
        <div class="a2">
            <span> {{ $t("menu.xNumber") }}: {{ 0 }} ，{{ $t("menu.xTips") }}</span>
        </div>
    </div> -->

    <div class="StakeAuthor">
      <div class="Author" v-if="empowerbutton == false">
        <!-- appoove button start-->
        <button :disabled="empowerbutton" class="StakeBtn ripple" @click="empower" v-if="typeApprove == 0">
          {{ $t("stake.Appoove") }} Swift
        </button>
        <button class="StakeBtn" v-if="typeApprove == 1">
          {{ $t("stake.Appooveing") }}
        </button>
        <!-- appoove button end -->
      </div>
      <div class="Author" v-else-if="Falconall == false">
        <!-- appoove button start-->
        <button :disabled="Falconall" class="StakeBtn ripple" @click="empowerfalcon" v-if="typeApprove == 0">
          {{ $t("stake.Appoove") }} Falcon
        </button>
        <button class="StakeBtn" v-if="typeApprove == 1">
          {{ $t("stake.Appooveing") }}
        </button>
        <!-- appoove button end -->
      </div>
      <!-- 当前合约用户是否存入 -->
      <!-- 不可点击 -->
      <div class="Stakeing" v-if="stakedMoney <= 0">
        <button :disabled="true" class="StakeBtn ripple">
          {{ $t("menu.Launch") }}
        </button>
      </div>
      <!-- 正常流程 -->
      <div class="Stakeing" v-else>
        <button v-if="empowerbutton == true && Falconall == true" class="StakeBtn ripple" @click="getindex">
          {{ $t("menu.Launch") }}
        </button>
        <button :disabled="depositDisabled" v-else class="StakeBtn ripple" @click="getindex">
          {{ $t("menu.Launch") }}
        </button>

      </div>
    </div>

    <div><img class="bStyle" :src="stylea" alt=""/></div>
    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg"/>
    </div>
    <!--loading end-->
    <!--loading start-->
    <div v-if="isloading2" class="loading">
      <div class="tips">正在寻找套利机会</div>
      <img :src="loadingimg" class="loadimg"/>
    </div>
    <!--loading end-->
  </div>
</template>
<style scoped src="@/assets/css/FlashLoans.css"></style>
<style lang="less" scoped>
.infoContent {
  display: flex;

  align-items: left;
  justify-content: left;
  gap: 5px;
  text-align: left;
  font-size: 14px;
  height: 100%;
  margin-bottom: 20px;
  .infoImg{
    img{
      position: relative;
      top: 3px;
      width: 16px ;
      height: 16px ;
    }
  }
  .infoText{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
  }
}
.ownwalletInfo {
  font-family: "Poppins", sans-serif;
  display: flex;
  margin-bottom: 2px;

  .ownTips {
    font-size: 17px;  
    font-weight: 100 !important;
    flex: 1;
  }

  .ownMoney {
    font-size: 17px;
    font-family: 'Advent Pro', sans-serif;

    img.daimi {
      width: 20px;
  height: 20px;
  margin-left: 5px;
    }

    img.swap_falcon {
      margin-left: 5px;
      width: 15.5px;
      height: 15.5px;
    }
  }
}
</style>
<script>
import ProgressBar from "@/components/ProgressBar.vue";
import swap_swfit from "@/assets/img/swift2.svg";
import swapUsdt from "@/assets/img/swap_usdt.svg";
import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import loadingimg from "@/assets/img/loading.svg";
import FKmini from "@/assets/img/FKmini.svg";
import FG1 from "@/assets/img/FG1.svg";
import D1 from "@/assets/img/d1.svg";
import D2 from "@/assets/img/d2.svg";
import daimni from "@/assets/img/daimini.svg";
import swap_falcon from "@/assets/img/swap_falcon.svg";
import DAImini2 from "@/assets/img/DAImini2.svg";
import swap_matic from "@/assets/img/swap_matic.svg";
import stylea from "@/assets/img/btnimg.png";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import loan from "@/plugins/loan.js";
import Falcon2js from "@/plugins/Falcon2js.js";
import Web3 from "web3";
import axios from "axios";
import sign from "@/plugins/sign.js";

export default {
  data() {
    return {
      isButtonDisabled: true,
      depositDisabled: true,
      typeApprove: 0,
      typedeposit: 0,
      dai: dai,
      swapUsdt:swapUsdt,
      FG1: FG1,
      D1: D1,
      D2: D2,
      swap_swfit:swap_swfit,
      daimni: daimni,
      swap_falcon: swap_falcon,
      DAImini2: DAImini2,
      swap_matic: swap_matic,
      loadingimg: loadingimg,
      FKmini: FKmini,
      daiValue: "100",
      daiOwnMoney: 0,
      stylea: stylea,
      stakedMoney: 0,
      currentProgress: 0,
      usersdata: {},
      sitedata: {},
      addresscode: "",
      empowerbutton: true,
      needinvest: "",
      Minimal: 0,
      isloading: false,
      isloading2: false,
      whiteAddresstype: false,
      isStarttype: false,
      flashAddresstype: true,
      userCallsvalue: 0,
      returndatadata: "",
      promptvalue: "",
      Falconall: false,
      Falconbalance: 0,
      getMaticBalancevalue: 0,
      endTime: null,
      countdown: '',
      timer: null,
      timershow: false,
      finishshow: false
    };
  },
  components: {
    Marquee,
    //ProgressBar,
  },
  unmounted() {
    clearInterval(this.timer)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },

  filters: {

    fildecimals(e) {
      const multipliedNumber = e * 10000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 10000;
      //    console.log(result);
      //    console.log(this.daiOwnMoney);
      let value = parseFloat(result);
      return value;
    },
  },
  async mounted() {
    // Determine whether the wallet is connected
    if (typeof window.ethereum !== "undefined") {
      //console.log("Connected wallet");

      // Async obtaining network information
      window.ethereum
        .request({method: "eth_chainId"})
        .then((chainId) => {
          // is Polygon?
          if (chainId === "0x38") {
            // console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //  console.log("The current network is not Polygon chain");
            this.$router.push({path: "/"});
            return;
          }
        })
        .catch((error) => {
          console.error("Failed to obtain network information. Procedure:", error);
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi;
    const currentAddress = await web3Utils.connectWallet();
    // console.log(currentAddress);
    this.addresscode = currentAddress;
    if (currentAddress) {
      this.daiOwnMoney = parseFloat(
        await web3Utils.getDaiBalance(this.addresscode)
      );
      console.log(this.daiOwnMoney)
      //  console.log(this.daiOwnMoney.toFixed(6));

      this.signtype()
      // this.exchangeallowance()
    }
  },
  methods: {
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {
          console.log(res, '签名验证')
          if (res.code == 0) {
            this.users();
            this.exchangeallowance();

            this.minInvestAmount();
            this._whitesAddress()
            this.isStart()
            // this._flashAddress()
            this.userCalls()
            this.balance()
            this.onMATIC()
          }
          this.isloading = false;
        });
    },
    funlastcalltime() {
      const date = this.lastcalltimevalue * 1000

      console.log(date, '22222222222222222222', this.lastcalltimevalue * 1000 * 24)
// 用法示例

      const storedEndTime = date + (1 * 24 * 60 * 60) * 1000;
      console.log(storedEndTime, 'aaaaaaaaaaaaa')
// localStorage.getItem('endTime');
      if (storedEndTime) {
        this.endTime = parseInt(storedEndTime, 10);
      } else {
        this.startCountdown(1 * 24 * 60 * 60); // 5天倒计时
      }
      this.updateCountdown(date);
// setInterval(this.updateCountdown, 1000);
      this.timer = setInterval(() => {
        //需要定时执行的代码
        this.updateCountdown(date)
      }, 1000)
    },
    startCountdown(duration) {
      this.endTime = Date.now() + duration * 1000;
      localStorage.setItem('endTime', this.endTime);
    },
    updateCountdown(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime - now <= 0) {
          console.log('结束')
          this.finishshow = true
          clearInterval(this.timer)
        }
      }


      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if (this.pad(hours) == null) {
        this.countdown = 0
        return
      }

      this.countdown = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
    async onMATIC() {
      var that = this
      this.getMaticBalancevalue = parseFloat(
        await web3Utils.getMaticBalance(this.addresscode)
      );

      console.log(this.getMaticBalancevalue, 'getMaticBalancevalue')
    },
    async _whitesAddress() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.loan_token.address;
      await loan
        ._whitesAddress(
          this.addresscode,
          ContractAddress,
          _decimals,
        )
        .then((res) => {
          console.log(res, '_whitesAddress')
          this.whiteAddresstype = res.data
        });
    },
    async isStart() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.loan_token.address;
      await loan
        .isStart(
          this.addresscode,
          ContractAddress,
          _decimals,
        )
        .then((res) => {
          console.log(res, 'isStart')
          this.isStarttype = res.data
          console.log(this.isStarttype)
        });
    },
    async _flashAddress() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.loan_token.address;
      await loan
        ._flashAddress(
          this.addresscode,
          ContractAddress,
          _decimals,
        )
        .then((res) => {
          console.log(res, '_flashAddress')
          this.flashAddresstype = res.data
          if (this.userCallsvalue) {
            this.promptvalue = this.$t("network.flashloansError")
          } else {
            this.promptvalue = this.$t("network.flashloansOnce")
          }

        });
    },
    async userCalls() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.falconv3_token.address;
      await Falcon2js
        .userCalls(
          this.addresscode,
          ContractAddress,
          _decimals,
        )
        .then((res) => {
          console.log(res, 'userCalls')
          this.userCallsvalue = res.data

          if (this.userCallsvalue >= 10) {
            this.promptvalue = '您还剩余0次'
            this.lastcalltime()
          } else {
            var value = 10 - this.userCallsvalue
            this.promptvalue = '您还剩余' + value + '次'
          }
        });
    },
    async lastcalltime() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.falconv3_token.address;
      await Falcon2js
        .lastcalltime(
          this.addresscode,
          ContractAddress,
          _decimals,
        )
        .then((res) => {
          console.log(res, 'lastcalltime')
          this.lastcalltimevalue = res.data
          this.funlastcalltime()
        });
    },
    async getdata() {
      const currentAddress = await web3Utils.connectWallet();
      // console.log(currentAddress);
      this.addresscode = currentAddress;
      if (currentAddress) {
        this.daiOwnMoney = parseFloat(
          await web3Utils.getDaiBalance(this.addresscode)
        );
        console.log(this.daiOwnMoney)
        //console.log(this.daiOwnMoney.toFixed(6));
        this.users();

        this.minInvestAmount();
        //this.exchangeallowance()
      }
    },
    validateAmount() {
      //console.log(this.daiValue)
      //console.log(this.Minimal)
      console.log(this.formattedDaiOwnMoney)
      if (this.daiValue * 1 < this.formattedDaiOwnMoney) {
        // this.isButtonDisabled = true;
        // this.depositDisabled = true;
      } else {
        // this.isButtonDisabled = false;
        // this.depositDisabled = false;
        this.daiValue = this.formattedDaiOwnMoney
        this.exchangeallowance();
      }
    },
    // Falcon余额
    async balance() {
      var that = this
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.Falcon_token.address;
      await Plugens.balanceOf(
        Address,
        0,
        this.addresscode,
        _decimals
      ).then((result) => {
        console.log(result, "Falcon余额");
        this.Falconbalance = result.data

      });
    },
    // Authorization button
    empower() {
      this.isloading = true;  // open loading
      this.Approve();
    },
    // falcon授权
    empowerfalcon() {
      this.isloading = true;  // open loading
      this.Approve2();
    },
    // contract Approve
    async Approve() {
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.sitedata.dai_token.address;
      spender = this.sitedata.falconv3_token.address;
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens(
          Address, //
          this.daiValue, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          // console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.empowerbutton = true;
            this.validateAmount();
          } else {
            // this.typeApprove = 1;
            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },
    async Approve2() {
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.sitedata.Falcon_token.address;
      spender = this.sitedata.falconv3_token.address;
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens(
          Address, //
          this.daiValue, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          // console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.Falconall = true;
            this.validateAmount();
          } else {
            // this.typeApprove = 1;
            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },
    // View the number of authorized contracts
    async exchangeallowance() {
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.dai_token.address;
      spender = this.sitedata.falconv3_token.address;
      await Plugens.allowancetoken(
        Address,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        console.log(result.data, 'DAI授权数量')
        if (result.data * 1 > 0) {
          this.empowerbutton = true;
        } else {
          this.empowerbutton = false;
        }
        // console.log(this.daiValue <= result.data * 1, '授权数量授权数量')
        // if (this.daiValue <= result.data * 1) {
        //     this.depositDisabled = false;
        // } else {
        //     this.depositDisabled = true;
        // }
      });
      var Address2 = this.sitedata.Falcon_token.address;
      await Plugens.allowancetoken(
        Address2,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        console.log(result.data, 'Falcon授权数量')
        if (result.data * 1 > 0) {
          this.Falconall = true;
        } else {
          this.Falconall = false;
        }

      });
    },
    // Contract deposit
    async deposit() {
      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typedeposit = 1;
      try {
        await contract
          .deposit(
            Address, //Contract address
            this.daiValue, //quantity
            this.addresscode, //Current address
            _decimals //Fractional part
          )
          .then((res) => {
            // console.log(res);
            this.typedeposit = 0;
            this.isloading = false;
            if (res.code == 0) {
              this.typedeposit = 0;
              this.$Notice.success({
                title: this.$t("stake.StakeSuccessful")
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
              this.users();

              this.minInvestAmount();
              this.getdata();
            } else {
              // this.typeApprove = 1;
              this.isloading = false;
              this.$Notice.warning({
                title: this.$t("stake.StakeFailed")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          });
      } catch (error) {
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("stake.StakeFailed")
          //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
        });
        // console.log(error);
      }
    },


    // Minimum total input
    async minInvestAmount() {
      // console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      // console.log("Minimum total input");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .minInvestAmount(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          //      console.log(res, "Minimum total input");
          this.Minimal = res.data;
        });
    },
    // User information
    async users() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .users(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          //console.log(res, "User information");
          this.usersdata = res.data;
          this.stakedMoney = res.data.investAmount * 1;
          //console.log(this.stakedMoney);
          //const total = 14000;
          //this.currentProgress = (this.stakedMoney / total) * 100;
        });
    },
    // tb地址接口
    getindex() {
      this.isloading2 = true;  // open loading
      axios
        .get(this.sitedata.url.address + '/api/index/flash?address=' + this.addresscode + '&amount=' + this.daiValue, {})
        .then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.returndatadata = res.data.data
            this.stake()
          } else {
            this.$Notice.warning({
              title: res.data.msg,
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
          }
        })
    },

    // Stake button
    async stake() {

      var that = this
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      console.log(getGasPrice, 'getGasPricegetGasPricegetGasPrice')
      //  预估


      const estimatedGas = {
        from: that.addresscode, // 替换为发送者地址
        to: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // 替换为收款地址
        data: that.returndatadata, // 替换为转账金额
      }
      console.log(estimatedGas)
      // web3.eth.sendTransaction({
      //         from: that.addresscode, // 替换为发送者地址
      //         to: '0x905952f886282D90b308686c048f6F7F44495D86', // 替换为收款地址
      //         data: that.returndatadata, // 替换为转账金额
      //         gas: 41000,
      //         gasPrice: getGasPrice,
      //         // nonce: await web3.eth.getTransactionCount(that.addresscode), //
      //     })
      //     .then((res) => {
      //         console.log('sendTransaction:', res);
      //     })
      //     .catch((error) => {
      //         console.error('Error:', error);
      //     });
      web3.eth.estimateGas(estimatedGas)
        .then((res) => {
          console.log('Estimated Gas:', res);
          web3.eth.sendTransaction({
            from: that.addresscode, // replace sender address
            to: '0x794a61358D6845594F94dc1DB02A252b5b4814aD', // 替换为收款地址
            data: that.returndatadata, // 替换为转账金额
            gas: res,
            gasPrice: Math.round(getGasPriceto * 1.5),
          })
            .then((res) => {
              console.log('sendTransaction:', res);
              this.isloading2 = false;
              this.users();
              this.exchangeallowance();

              this.minInvestAmount();
              this._whitesAddress()
              this.isStart()
              // this._flashAddress()
              this.userCalls()
              this.balance()
              this.onMATIC()
              this.$Notice.success({
                title: this.$t("network.flashloansOK"),
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
            })
            .catch((error) => {
              console.error('Error:', error);
              this.isloading2 = false;
              this.$Notice.warning({
                title: this.$t("network.flashloansNo"),
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
            });
        })
        .catch((error) => {
          this.isloading2 = false;
          this.$Notice.warning({
            title: this.$t("network.flashloansNo"),
            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
          });
          console.error('Error:', error);
        });


    },

    warning(nodesc) {
      this.$Notice.warning({
        title: nodesc ? "" : this.$t("network.stake"),
        //desc: nodesc ? "" : this.$t("network.stake"),
      });
    },
  },
  computed: {
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.0000";
      console.log(this.daiOwnMoney)
      const multipliedNumber = this.daiOwnMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //    console.log(result);
      //    console.log(this.daiOwnMoney);
      let value = parseFloat(result);
      console.log(value)

      return value
    },
    formattedFalconbalance() {
      if (!this.Falconbalance) return "0.0000";
      console.log(this.Falconbalance)
      const multipliedNumber = this.Falconbalance * 10000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 10000;
      let value = parseFloat(result);
      console.log(value)

      return value
    },

    formattedDaiOwnMoney2() {
      if (!this.stakedMoney) return "0.0000";
      const multipliedNumber = this.stakedMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },
  },
};
</script>
