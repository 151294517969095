import { render, staticRenderFns } from "./EvSwapnew.vue?vue&type=template&id=e886680e&scoped=true"
import script from "./EvSwapnew.vue?vue&type=script&lang=js"
export * from "./EvSwapnew.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/EvSwap.css?vue&type=style&index=0&id=e886680e&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e886680e",
  null
  
)

export default component.exports