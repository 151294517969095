<template>
  <div class="stakewait">
    <div v-show="show == 1">
 
     <div class="contexta">
      
            <div class="coinInfomation" @click="isShown2 = true" v-if="deposittype==1">
                <div class="coinImg">
                  <img src="../assets/img/wealth.svg" class="dailogo" alt="">
                </div>
                <div class="coinInfo">
                  <span>{{$t("stake.wealth")}}</span>
                </div>
                <img :src="swaparr2" class="swaparrow" />  
            </div>

            <div class="coinInfomation" @click="isShown2 = true" v-if="deposittype==2">
              <div class="coinImg">
                <img src="../assets/img/stem.svg" class="dailogo" alt="">
              </div>
              <div class="coinInfo">
                <span>{{$t("stake.stem")}}</span>
              </div>
              <img :src="swaparr2" class="swaparrow" />
            </div>
            
            <div class="coinInfomation" @click="isShown2 = true" v-if="deposittype==3">
              <div class="coinImg">
                <img src="../assets/img/return.svg" class="dailogo" alt="">
              </div>
              <div class="coinInfo">
                <span>{{$t("stake.return")}}</span>
              </div>
              <img :src="swaparr2" class="swaparrow" />
            </div>
          
     </div>

      <!-- <div class="marquee" style="margin-bottom: 15px; color:aqua;">
        <Marquee :duration="15">{{ $t("network.notification") }}</Marquee>
      </div> -->

     

      <!-- <div class="ownwalletInfo"  v-if="formattedfInvestAmount>1">
        <div class="ownTips">{{ $t("Withdraw.stakedassets") }}:</div>

        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;" v-if="swap_Aname != 'DAI'">
          {{ formattedfInvestAmount }}<img class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
        </div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;" v-else>
          {{ formattedDaiOwnMoney }}<img class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
        </div>
      </div> -->

      <div class="ownwalletInfo" >
        <div class="ownTips">{{ $t("Withdraw.Availableassets") }}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;" >
        $  {{ getDepositDatadata[0] | fildecimals }}<img class="daimi" :src="swap_aimg"  />
        </div>
      </div>
 
      <div class="ownwalletInfo">
        <div class="ownTips">{{$t("Withdraw.LockedAassets")}}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;" >
          <img class="times" :src="swap_timeS" />
        $ {{getDepositDatadata[1] | fildecimals}}<img class="daimi" :src="swap_aimg" />
        </div>
      </div>
      <div class="ownwalletInfo" v-if="deposittype==3">
      <div class="ownTips">Return Value:</div>
      <div class="ownMoney" style="display: flex;align-items: center;">
      {{pointsAmount}}<img class="daimi" :src="swap_aimg"/>
      </div>
    </div>

      <Divider style="background: rgba(142, 200, 243, 0.5)" />

    <div class="infoContent">
      <div class="infoImg"> <img src="../assets/img/info.svg" alt=""></div>
      <div class="infoText">{{ $t("Withdraw.WithdrwaInfo") }}
        <router-link to="/LockedAssets">
          <span class="more">More</span> 
        </router-link></div>
    </div>


      <div class="inputInfo" >
        <input v-model.number="daiValue" type="number" class="inputStyle" @input="inputclick()" />
        <button @click="daiValue = getDepositDatadata[0], inputclick()" class="tbnStyle ripple">
          {{ $t("network.all") }}
        </button>
      </div>
  
<!--      <div class="ownwalletInfo" v-if="getWithdrawFalconTokenfalconAmount > 0">-->
<!--        <div class="ownTips">{{ $t("Withdraw.cangetfalcon") }}:</div>-->

<!--        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;">-->
<!--          {{ getWithdrawFalconTokenfalconAmount | fildecimals }}<img class="daimi" :src="swap_falcon"-->
<!--            style="height: 20px;width: 20px;" />-->
<!--        </div>-->
<!--      </div>-->

      <div class="WithDrawBtn">


        <!--        <button class="enabled-class " disabled v-if="swap_Aname == 'DAI' || swap_Aname == 'USDT'">-->
          <button class="enabled-class " disabled v-if="swap_Aname == 'DAI' ">
          {{ $t("Withdraw.Withdraw") }}
        </button>
        <button class="tbnStyle2 ripple" @click="withdraw()" v-else-if="typeextract == 0">
          {{ $t("Withdraw.Withdraw") }}
        </button>
        <button class="tbnStyle2 ripple" v-else>
          {{ $t("Withdraw.Processing") }}
        </button>

        <button class="tbnStyle2 ripple"  @click="withdraw3()" v-if="typeReceive == 0" >
            {{ $t("Withdraw.Getreward") }}
          </button>
          <button class="tbnStyle2 ripple" v-else >
            {{ $t("Withdraw.Processing") }}
          </button>

      
      </div>

      <div class="prompt" >
        * {{$t("Withdraw.WithdrawInfo2")}}
      </div>

      <h1 class="EarTitle">{{$t("Withdraw.EarningsTitle")}}:</h1>
      <Divider style="background: rgba(142, 200, 243, 0.5)" />

      <div class="ownwalletInfo" >
        <div class="ownTips tips2">{{ $t("Withdraw.basicEarnings") }}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;" >
        $  {{ formattedShareMoney }}<img class="daimi" :src="swap_aimg"  />
        </div>
      </div>

      <div class="ownwalletInfo" >
        <div class="ownTips tips2">{{ $t("Withdraw.ecosystemRewards") }}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;" >
        $  {{ formattedTemeMoney2 }}<img class="daimi" :src="swap_aimg"  />
        </div>
      </div>
      <div class="margonDown"></div>
    </div>
  
    <div><img class="bStyle" :src="stylea" alt="" /></div>
    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg" />
    </div>
    <!--loading end-->
    <div class="selectCoin" v-show="isShown2" style="height: auto;">

<div class="tipsandclose">
  <div class="t1">{{ $t("stake.investta") }}</div>
  <div class="closeimg"><img :src="swap_close" alt="" @click="isShown2 = !isShown2"></div>
</div>
<div class="allconin" style="justify-content: center;">
  <div class="coinBTN" @click="onselect2(1)">
    <img src="../assets/img/wealth.svg" alt="">
    <div class="coiname SN">{{ $t("stake.wealth") }}</div>
  </div>
  <div class="coinBTN"  @click="onselect2(2)">
    <img src="../assets/img/stem.svg" alt="">
    <div class="coiname SN">{{ $t("stake.stem") }}</div>
  </div>
  <div class="coinBTN" @click="onselect2(3)">
    <img src="../assets/img/return.svg" alt="">
    <div class="coiname SN">{{ $t("stake.return") }}</div>
  </div>
</div>
</div>
  </div>
</template>
<style scoped src="@/assets/css/EvWithdraw.css"></style>
<style lang="less" scoped>
.contexta{
  margin-bottom: 20px; display: flex;align-items: center;
  justify-content: center;
}
.swaparrow{
  width: 15px;
  height: 15px;
}
.SN{
  font-size: 14px;
}
.EarTitle{
  font-size: 17px;
}
.tips2{
  font-size:17px !important;
}
.infoContent {
  display: flex;   
  align-items: left; 
  justify-content: left; 
  gap: 5px; 
  text-align: left; 
  font-size: 14px;  
  height: 100%;
  margin-bottom: 20px;
  .infoImg{    
    img{
      position: relative;
      top: 3px;  
      width: 16px ;
      height: 16px ;
    }
  }
  .infoText{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
  }
  .more{
    text-decoration: underline;
  text-decoration-color: rgb(255, 254, 254); 
  color: white;
  cursor: pointer;
  }
}
.t1x{
  margin-bottom: 40px;
}
.WithDrawBtn {
  display: flex;
  justify-content: space-between;

  .tbnStyle3 {
    width: 134px;
    margin-bottom: 15px;
    height: 50px;
    line-height: 50px;
    position: relative;
    color: rgb(9, 0, 0);
    font-weight: bolder;
    top: 8px;
    border: none;
    outline: none;
    font-size: 20px;
    border-radius: 8px;
    padding: 0 10px;
    cursor: pointer;
    background-image: linear-gradient(126deg,
        rgba(209, 209, 209, 0.3),
        rgba(208, 208, 208, 0.3));
  }

}

.slider {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-button {
  width: 25px;
  height: 25px;
  line-height: 21px;
  background-color: rgb(172, 7, 114);
  color: #fff;
  border: 2px solid white;
  border-radius: 50px;
  text-align: center;
  font-size: 12px;
}

.tbnStyle2_div {
  background-color: rgb(3, 198, 101);
  color: #fff;
  width: 35px;
  height: 20px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  border-radius: 5px;
  margin-right: 5px;

}

.fanhui {
  display: flex;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 10px;
}

.fanhui img {
  width: 16px;
  height: 16px;
}
.nowNumber {
  text-align: center;
}
.endTime {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #08ebeb;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .prompt{
    color: #f1f1f1;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  
  .coinInfomation {
   
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 2.66667vw;
  height: 40px;  
  border-radius: 50px;
}
.coinInfo {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  position: relative;
  top: 0px;
  left: 10px;
  font-weight: bold;
  color: #000;
}
</style>
<script>
import swap_falcon from "@/assets/img/swap_falcon.svg";
import swaparr2 from "@/assets/img/arr2.svg";
import swap_timeS from "@/assets/img/timeS.svg";
import swap_withdraw from "@/assets/img/withdraw.svg";
import swap_close from "@/assets/img/swap_close.svg";
import swap_arrow from "@/assets/img/swap_arrow.svg";
import swap_speed from "@/assets/img/speed.svg";
import swap_crow from "@/assets/img/crow.svg";
import swap_dai from "@/assets/img/swap_dai.svg";
import swap_usdc from "@/assets/img/swap_usdc.svg";
import swap_usdt from "@/assets/img/swap_usdt.svg";
import web3Utils from "@/utils/web3Utils.js";
import Marquee from "@/components/Marquee.vue";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/btnimg.png";
import contract from "@/plugins/contract.js";
import loadingimg from "@/assets/img/loading.svg";
import siteapi from "@/json/siteapi.json";
import sign from "@/plugins/sign.js";
import Vue from "vue";
import { Slider } from 'vant';
import aaveChild from "@/plugins/aaveChild";

Vue.use(Slider);
import Plugens from "@/plugins/index";

export default {
  name: "EvWithdraw",
  data() {
    return {
      swaparr2,
      swap_falcon,
      swap_timeS,
      swap_withdraw,
      swap_close,
      swap_arrow,
      swap_dai,
      swap_crow,
      swap_speed,
      swap_usdc,
      swap_usdt,
      dai: dai,
      daimni: daimni,
      daiValue: 0,
      daiOwnMoney: 0,
      daiShareMoney: 0,
      daiTemeMoney: 0.0,
      daiTemeMoney2: 0,
      stylea: stylea,
      usersdata: {},
      typeextract: 0,
      typeReinvestment: 0,
      typeReceive: 0,
      loadingimg: loadingimg,
      isloading: false,
      swapimg2: "",
      swapname: "",
      swap_decimalsA: 18,
      isShown2: false,
      swap_Aname: "",
      swap_aimg: "",
      swap_Aaddress: "",
      tokenUsersinvestAmount: 0,
      show: 1,
      slidervalue: 1,
      burnGetMyUserBurnInterestvalue1: 0,
      burnGetMyUserBurnInterestvalue2: 0,
      burnGetMyUserBurnInterestvalue3: 0,
      burnGetMyUserBurnInterestvalue4: 0,
      burnLevel: 0,
      burnLeveltype: 0,
      daiOwnMoneyjh: 0,
      falconAmount: 0,
      daiValue2: 0,
      burnContractAddressvalue: "",
      falconshow: false,
      typeApprove: 0,
      typeBurn: 0,
      sliderdisabled: false,
      burnUsersvalue: 0,
      burnAmount: 0,
      countdown: '',
      countdown1: '',
      timer: null,
      timershow: false,
      finishshow: false,
      timershow1: false,
      finishshow1: false,
      endTime:0,
      endTime1:0,
      firingtype: true,
      daiValue2small: 0,
      lastcalltimevalue: 0,
      lastcalltimevalue1: 0,
      lastExtractTime: 0,
      getWithdrawFalconTokenfalconAmount: 0,
      tokenUsersFInvestAmount: 0,
      burnSecond:0,
      getMyUserBurnDayInterestdata:0,
      lpBurnDayUsersinvestAmountIsBurn:false,
      toptypeshow:true,
      usdtinvestAmount:0,
      DAIinvestAmount:0,
      falconTokenUsersRewardAmount: 0,
      getDepositDatadata:[
        0,0,0,
      ],
      deposittype:1,
      withdrawTokentype:1,
      pointsAmount:0

    };
  },
  components: {
    Marquee,

  },
  filters: {

    fildecimals(e) {
      const multipliedNumber = e * 10000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 10000;
      //    console.log(result);

      let value = parseFloat(result);
      return value;
    },

  },
  async mounted() {
    // Determine whether the wallet is connected
    if (typeof window.ethereum !== 'undefined') {
      //console.log("Connected wallet");

      // Asynchronously obtaining network information
      window.ethereum
        .request({ method: 'eth_chainId' })
        .then((chainId) => {
          // Determine whether the current network is a Polygon chain
          if (chainId === "0x38") {
            //console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //console.log("The current network is not Polygon chain");
            this.$router.push({ path: '/' });
            return
          }
        })
        .catch((error) => {
          //console.error('Failed to obtain network information. Procedure:', error);
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi
    const currentAddress = await web3Utils.connectWallet();
    //console.log(currentAddress)
    this.addresscode = currentAddress
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(currentAddress)
      // );

      // console.log(this.daiOwnMoney.toFixed(6));
      this.signtype()
      // this.exchangeallowance()
    }
  },
  methods: {
    funlastcalltime() {
      const now = Date.now();
      const date = this.lastcalltimevalue * 1 + this.lastExtractTime * 1000

      console.log(date, '22222222222222222222', this.lastExtractTime, this.lastcalltimevalue)
      // 用法示例

      const storedEndTime = date
      console.log(storedEndTime, 'aaaaaaaaaaaaa')
      // localStorage.getItem('endTime');
      if (storedEndTime) {
        this.endTime = parseInt(storedEndTime, 10);
      } else {
        this.startCountdown(1 * 24 * 60 * 60); // 5天倒计时
      }
      this.updateCountdown(date);
      // setInterval(this.updateCountdown, 1000);
      this.timer = setInterval(() => {
        //需要定时执行的代码
        this.updateCountdown(date)
      }, 1000)
    },
    funlastcalltime1() {
      const now = Date.now();
      const date = this.lastcalltimevalue1 * 1 + this.lastExtractTime * 1000

      console.log(date, '22222222222222222222', this.lastExtractTime, this.lastcalltimevalue1)
      // 用法示例

      const storedEndTime = date
      console.log(storedEndTime, 'aaaaaaaaaaaaa')
      // localStorage.getItem('endTime');
      if (storedEndTime) {
        this.endTime1 = parseInt(storedEndTime, 10);
      } else {
        this.startCountdown1(1 * 24 * 60 * 60); // 5天倒计时
      }
      this.updateCountdown1(date);
      // setInterval(this.updateCountdown, 1000);
      this.timer1 = setInterval(() => {
        //需要定时执行的代码
        this.updateCountdown1(date)
      }, 1000)
    },
    startCountdown(duration) {
      this.endTime = Date.now() + duration * 1000;
      localStorage.setItem('endTime', this.endTime);
    },
    startCountdown1(duration) {
      this.endTime1 = Date.now() + duration * 1000;
      localStorage.setItem('endTime1', this.endTime1);
    },
    updateCountdown(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime - now <= 0) {
          console.log('结束')
          this.lastcalltimevalue = 0
          this.finishshow = true
          clearInterval(this.timer)
        }
      }
      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if (this.pad(hours) == null) {
        this.countdown = 0
        return
      }

      this.countdown = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    updateCountdown1(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow1 == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow1 = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime1 - now <= 0) {
          console.log('结束')
          this.lastcalltimevalue = 0
          this.finishshow1 = true
          clearInterval(this.timer1)
        }
      }


      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if (this.pad(hours) == null) {
        this.countdown1 = 0
        return
      }

      this.countdown1 = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {
          console.log(res, '签名验证')
          if (res.code == 0) {
            this.swapimg2 = this.swapswap_usdt_dai
            this.swapname = 'USDT'
            // this.swap_decimalsA = 6;
            this.swap_Aaddress = this.sitedata.USDT_swap.address
            this.swap_aimg = this.swap_usdt
            this.swap_Aname = 'USDT'
            this.users()

          }
          this.isloading = false;
        });
    },
    async pointsTokenUsers() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .pointsTokenUsers(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals,
            this.sitedata.USDT_swap.address
          )
          .then((res) => {
            console.log(res,'pointsTokenUsers')
         this.pointsAmount=res.data.pointsAmount
         this.isExit=res.data.isExit
          });
      },
    async getDepositData() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .getDepositData(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals,
            this.sitedata.USDT_swap.address
          )
          .then((res) => {
            console.log(res,'getDepositData')
         this.getDepositDatadata=res.data
          });
      },
    async getMyUserBurnDayInterest() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.getMyUserBurnDayInterest(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress
      ).then((res) => {
        console.log(res, 'getMyUserBurnDayInterest');
        this.getMyUserBurnDayInterestdata = res.data * 1

      });
    },
    onChange(e) {
      console.log(this.slidervalue)
      this.burnGetMinFalconToken()
      this.exchangeallowance()
      //this.burnRules()

    },
    inputclick() {
      if (this.swap_Aname != 'DAI') {
        if (this.daiValue > this.formattedMyInvestAmount) {
          this.daiValue = this.formattedMyInvestAmount
        }
      } else {
        if (this.daiValue > this.formattedDaiOwnMoney) {
          this.daiValue = this.formattedDaiOwnMoney
        }
      }
      // if (this.daiValue > 0) {
      //   this.getWithdrawFalconToken()
      // } else {
      //   this.getWithdrawFalconTokenfalconAmount = 0
      // }
    },
    async getWithdrawFalconToken() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('User information')
      ContractAddress = this.sitedata.aave_token.address;
      await aaveChild.getWithdrawFalconToken(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.daiValue
      ).then((res) => {
        console.log(res, 'getWithdrawFalconToken');
        this.getWithdrawFalconTokenfalconAmount = res.data.falconAmount

      });
    },
    // User information
    async users() {
      this.isloading = true;
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('User information')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.users(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals
      ).then((res) => {
        this.isloading = false;
        console.log(res, 'User information');
        this.usersdata = res.data
        this.daiOwnMoney = res.data.investAmount * 1
        this.daiTemeMoney = res.data.rewardAmount * 1
        this.getMyUserInterest()
        // this.getMyUserBurnDayInterest()
        this.tokenUsers()
        this.lpBurnDayUsers()
        this.burnGetMyUserBurnInterest1()
        this.burnGetMyUserBurnInterest2()
        this.burnUsers()
        this.pointsTokenUsers()
        this.balance2()
        this.exchangeallowance()
        this.getDepositData()

 
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },
    // 是否授权
    async exchangeallowance() {
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.falcon_swap.address;
      spender = this.sitedata.aave_token.address;
      await Plugens.allowancetoken(
        Address,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        console.log(result, 'falcon_swap是否授权1111')
        this.isloading = false;
        if (result.data * 1 >= this.daiValue2) {
          this.falconshow = true;
        } else {
          this.falconshow = false;
        }

      });
    },
    async Approve() {
      this.isloading = true; // close loading
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.sitedata.falcon_swap.address;
      spender = this.sitedata.aave_token.address;
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens2(
          Address, //
          this.daiValue2, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          // console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.falconshow = true;
          } else {
            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {

        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },

    // tokenUsers
    async tokenUsers() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .tokenUsers(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          this.swap_Aaddress
        )
        .then((res) => {
          console.log(res, 'tokenUsers')
          
          this.falconTokenUsers();
          
          this.tokenUsersinvestAmount = res.data.investAmount
          this.tokenUsersFInvestAmount = res.data.fInvestAmount
          this.daiTemeMoney2 = res.data.rewardAmount * 1
          if (this.swap_Aname != 'DAI') {
            if (this.tokenUsersinvestAmount <= 0) {
              this.sliderdisabled = true
            } else {
              this.sliderdisabled = false
            }
          } else {

            if (this.daiOwnMoney <= 0) {

              this.sliderdisabled = true
            } else {
              this.sliderdisabled = false
            }
          }
        });
    },
    
    async falconTokenUsers() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .tokenUsers(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          this.sitedata.falcon_swap.address
        )
        .then((res) => {
          console.log(res, 'falconTokenUsers')
          this.falconTokenUsersRewardAmount = res.data.rewardAmount * 1
        });
    },
    
    async lpBurnDayUsers() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.aave_token.address;
      await contract.lpBurnDayUsers(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.sitedata.USDT_swap.address,
      ).then((res) => {
        console.log(res, 'lpBurnDayUsers');
       // this.lpBurnDayUsersinvestAmount=res.data.investAmount
        if(res.data.lpInvestAmount >0){
          this.lpBurnDayUsersinvestAmountIsBurn =true;
        }else{
          this.lpBurnDayUsersinvestAmountIsBurn =false;
        }
      });
    },


  
    onselect2(type){
      this.deposittype=type
      if(this.deposittype==3){
        // this.value1=this.pointsAmount*2
        this.withdrawTokentype=3
      }else{
         this.withdrawTokentype=1
      }
      this.isShown2 = false
    },
    // 选择币种
    async onselect(img, name, site, _decimals, swaptype) {
      this.swap_decimalsA = _decimals
      this.swap_aimg = img
      this.swap_Aname = name
      this.swap_Aaddress = this.sitedata[site].address
      console.log(this.sitedata[site].address, 'AAAAAAAAAAAAAA')
      //             this.balance()
      // this.exchangeallowance()
      this.isShown2 = false
      this.users()
      this.tokenUsers()
      this.getMyUserInterest()
      // this.getMyUserBurnDayInterest()
      this.burnGetMyUserBurnInterest()
      this.burnUsers()
      this.balance2()
      this.exchangeallowance()

    },

    // Personal income
    async getMyUserInterest() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.getMyUserInterest(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress,
        1
      ).then((res) => {
        console.log(res, 'getMyUserInterest');
        this.daiShareMoney = res.data * 1
        //console.log(this.daiShareMoney)
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },

    async burnGetMyUserBurnInterest1() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.burnGetMyUserBurnInterest(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress,
        1
      ).then((res) => {
        console.log(res, 'burnGetMyUserBurnInterest1');
        this.burnGetMyUserBurnInterestvalue1 = res.data.totalInterest
        this.burnGetMyUserBurnInterestvalue3 = res.data.withdrawableInterest
        this.lastcalltimevalue = res.data.timeRemaining * 1000

      });
    },
    async burnGetMyUserBurnInterest2() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.burnGetMyUserBurnInterest(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress,
        2
      ).then((res) => {
        console.log(res, 'burnGetMyUserBurnInterest2');
        this.burnGetMyUserBurnInterestvalue2 = res.data.totalInterest
        this.burnGetMyUserBurnInterestvalue4 = res.data.withdrawableInterest
        this.lastcalltimevalue1= res.data.timeRemaining * 1000
      });
    },
    async burnUsers() {
      this.burnGetMinFalconToken()
        this.burnRules()
        this.exchangeallowance();
    },
    async burnGetMinFalconToken() {
      var asdas = this.slidervalue
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.burnGetMinFalconToken(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress,
        asdas
      ).then((res) => {
        console.log(res, 'burnGetMinFalconToken');
        // this.burnGetMinFalconTokenvalue=res.data[1]
        this.daiValue2 = this.funvalue(res.data[1] * 1.00001)
        this.daiValue2small = this.funvalue(res.data[1] * 1.00001)

        this.exchangeallowance();
      });
    },
    inputvalue2(e) {
      // if(this.daiValue2<=this.daiValue2small){
      //   this.daiValue2=this.daiValue2small
      // }
      this.exchangeallowance();
    },
    async burnRules() {
      var asdas = this.slidervalue

      asdas = 1
      console.log(asdas, '12333333333')
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.burnRules(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress,
        asdas
      ).then((res) => {
        console.log(res, 'burnRules');
        if (this.swap_Aname != 'DAI') {

          if (this.tokenUsersinvestAmount * 1 >= res.data.investAmount * 1) {
            this.firingtype = false
          } else {
            this.firingtype = true
          }

        } else {
          console.log('DAIDAI')
          if (this.daiOwnMoney * 1 >= res.data.investAmount * 1) {
            this.firingtype = false
          } else {
            this.firingtype = true
          }
        }
        console.log(this.tokenUsersinvestAmount, res.data.investAmount, 'burnRulesburnRulesburnRules', this.firingtype)
      });
    },
    funvalue(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
    // 激活余额
    async balance2() {
      var that = this
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.falcon_swap.address
      await Plugens.balanceOf(
        Address,
        0,
        this.addresscode,

        _decimals
      ).then((result) => {
        console.log(result, "falcon余额");
        this.daiOwnMoneyjh = result.data
      });
    },
    //withdraw button
    withdraw() {
      if (this.daiValue <= 0) {

        this.$Notice.warning({
          title: this.$t("Withdraw.WithdrawInfo1")
        });
        return
      }
      // if (this.daiValue > this.daiOwnMoney) {

      //   this.$Notice.warning({
      //     title: this.$t("Withdraw.WithdrawInfo2")
      //   });
      //   return
      // }
      this.isloading = true;
      this.withdrawfun();
      // this.warning(false);
    },

    async burnDepositToken() {
      this.isloading = true;
      let _decimals = 18;
      let Address;
      let falconAmount;
      Address = this.sitedata.aave_token.address;
      this.typeBurn = 1;
      try {
        var asdas = this.slidervalue;


        if (this.falconAmount - this.daiValue2 > 0) {
          falconAmount = 0;
        } else {
          falconAmount = this.daiValue2 - this.falconAmount;
        }

        falconAmount =
          await contract.burnDepositToken(
            Address, //Contract address
            this.addresscode,//Current address
            _decimals,//Fractional part
            this.sitedata.aave_token.address,
            asdas,
            falconAmount,
            this.swap_Aaddress,
          ).then((res) => {
            console.log(res);
            this.typeBurn = 0;
            if (res.code == 0) {
              this.typeBurn = 0;
              this.isloading = false;
              this.show = 1
              this.$Notice.success({
                title: this.$t("Withdraw.successful")

              });
              this.getMyUserInterest()
              // this.getMyUserBurnDayInterest()
              this.tokenUsers()
              this.burnGetMyUserBurnInterest1()
              this.burnGetMyUserBurnInterest2()
              this.burnUsers()
              this.balance2()
              this.exchangeallowance()


            } else {
              // this.typeApprove = 1;
              this.isloading = false;
              if (res.code < 1) {
                this.$Notice.warning({
                  title: res.error.message
                });
              } else {
                this.$Notice.warning({
                  title: this.$t("Withdraw.unsuccessfule")
                  // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
              }
            }

          });
      } catch (error) {
        this.isloading = false;
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    // withdraw
    async withdrawfun() {
      let _decimals = this.swap_decimalsA;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeextract = 1;
      try {
        await contract.withdrawToken(
          Address, //Contract address
          this.daiValue, //quantity
          this.addresscode,//Current address
          _decimals,//Fractional part
          this.swap_Aaddress,
          this.withdrawTokentype
        ).then((res) => {
          console.log(res);
          this.typeextract = 0;
          if (res.code == 0) {
            this.typeextract = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")

            });
            this.users()

          } else {
            // this.typeApprove = 1;
            this.isloading = false;
            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }

        });
      } catch (error) {
        this.isloading = false;
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    withdraw2() {
      this.withdrawBurnInterestAndRewardToken()
      // var value = this.daiShareMoney + this.daiTemeMoney
      // //console.log(value)
      // if (value >= 0) {
      //   //console.log(123);
      //   this.isloading = true;
      //   this.depositInterestAndReward()
      // } else {
      //   this.$Notice.warning({
      //     title: this.$t("Withdraw.WithdrawInfo2")
      //   });
      // }
      // this.warning2(false);
    },
    // reinvestment
    async withdrawBurnInterestAndRewardToken() {
      //console.log("reinvestment")
      this.isloading = true;
      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeReinvestment = 1;
      try {
        await contract.withdrawBurnInterestAndRewardToken(
          Address, //Contract address
          this.daiValue, //quantity
          this.addresscode,//Current address
          _decimals,//float
          this.swap_Aaddress
        ).then((res) => {
          console.log(res);
          this.typeReinvestment = 0;
          if (res.code == 0) {
            this.typeReinvestment = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")
            });
            this.users()

          } else {
            this.isloading = false;

            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }


          }

        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    withdraw3() {
      this.withdrawInterestAndRewardToken()
      // var value = this.daiShareMoney + this.daiTemeMoney
      // //console.log(value)
      // if (value >= 0) {
      //   this.isloading = true;
      //   this.withdrawInterestAndRewardToken()
      // } else {
      //   this.$Notice.warning({
      //     title: this.$t("Withdraw.WithdrawInfo2")
      //   });
      // }
    },
    // withdraw
    async withdrawInterestAndRewardToken() {
      this.isloading = true;
      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeReceive = 1;
      try {
        await contract.withdrawInterestAndRewardToken(
          Address, //Contract address
          this.daiValue, //number
          this.addresscode,//current address
          _decimals,//float
          this.swap_Aaddress,
          1
        ).then((res) => {
          //console.log(res);
          this.typeReceive = 0;
          if (res.code == 0) {
            this.typeReceive = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")
            });
            this.users()

          } else {
            // this.typeApprove = 1;
            this.isloading = false;

            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }

        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    awaitinterest() {
      this.interest();
    },
    async interest() {
      this.isloading = true;
      let Address;
      Address = this.sitedata.aave_token.address;
      //this.typeReceive = 1;
      try {
        await contract.interest(
          Address, //Contract address
          this.addresscode,//current address
          this.swap_Aaddress
        ).then((res) => {
          //console.log(res);
          //this.typeReceive = 0;
          if (res.code == 0) {
            //this.typeReceive = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")
            });
            this.users()
          } else {
            // this.typeApprove = 1;
            this.isloading = false;

            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }
        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },


    warning(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.withdraw"),
      });
    },
    warning2(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Reinvest"),
      });
    },
    warning3(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Getreward"),
      });
    },
  },

  computed: {

    formattedfInvestAmount() {
      if (!this.tokenUsersFInvestAmount) return "0.0000";
      const multipliedNumber = this.tokenUsersFInvestAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },

    formattedDaiOwnMoney2() {
      if (!this.tokenUsersinvestAmount) return "0.0000";
      const multipliedNumber = this.tokenUsersinvestAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.00";
      const multipliedNumber = this.daiOwnMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      //console.log(this.daiOwnMoney)
      let value = parseFloat(result);
      return value
    },
    formattedShareMoney() {
      if (!this.daiShareMoney) return "0.00";
      const multipliedNumber = this.daiShareMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
    formattedTemeMoney() {
      if (!this.daiTemeMoney) return "0.00";
      const multipliedNumber = this.daiTemeMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
    formattedTemeMoney2() {
      if (!this.daiTemeMoney2) return "0.00";
      const multipliedNumber = this.daiTemeMoney2 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
    formattedDaiOwnMoney3() {
      if (!this.daiOwnMoneyjh) return "0.0000";
      const multipliedNumber = this.daiOwnMoneyjh * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;

      let value = parseFloat(result);
      return value
    },
    burnFalconAmount() {
      if (!this.falconAmount) return "0.0000";
      const multipliedNumber = this.falconAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      let value = parseFloat(result);
      return value
    },


    formattedDaiOwnMoney4() {
      if (!this.burnGetMyUserBurnInterestvalue1) return "0.000";

      const multipliedNumber = this.burnGetMyUserBurnInterestvalue1 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;

      let value = parseFloat(result);


      return value
    },
    formattedDaiOwnMoney5() {
      if (!this.burnGetMyUserBurnInterestvalue2) return "0.000";
      const multipliedNumber = this.burnGetMyUserBurnInterestvalue2 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      let value = parseFloat(result);
      return value
    },
    formattedDaiOwnMoney7() {
      if (!this.burnGetMyUserBurnInterestvalue4) return "0.000";
      const multipliedNumber = this.burnGetMyUserBurnInterestvalue4 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      let value = parseFloat(result);
      return value
    },
  },
};
</script>
