import { render, staticRenderFns } from "./FalconIdo4.vue?vue&type=template&id=3148cff9&scoped=true"
import script from "./FalconIdo4.vue?vue&type=script&lang=js"
export * from "./FalconIdo4.vue?vue&type=script&lang=js"
import style0 from "./FalconIdo4.vue?vue&type=style&index=0&id=3148cff9&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3148cff9",
  null
  
)

export default component.exports