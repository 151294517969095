<template>
  <div class="stakewait">
    <div >

      <div class="top_button">
        <div class="coinInfomation" >
          <div class="coinImg"><img :src="lockedtime" class="dailogo" /></div>
          <div class="coinInfo">{{$t("locked.title")}}</div>
          <!-- <img :src="swap_arrow" class="swaparrow" /> -->
        </div>
      </div>

      <!-- <div class="marquee" style="margin-bottom: 15px; color:aqua;">
        <Marquee :duration="15">{{ $t("network.notification") }}</Marquee>
      </div> -->

      <Divider style="background: rgba(142, 200, 243, 0.1)" />


      <div class="ownwalletInfo">
        <div class="ownTips">{{$t("locked.genetic")}} </div>
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.5)" />

      <div  v-for="(item,index) in getFixedUserDepositslist" :key="index">
        <div v-if="item.buyTimestamp>0" class="StakeInfo">
          <div class="StakeResult">· {{ formatTimestampToMinute(item.buyTimestamp*1000) }}  <span>{{ item.productType }}</span></div>
          <div class="StakeMoney" style="display: flex;align-items: center;text-align: center;">
            {{item.investAmount}}
            <img  class="daimi" :src="swap_usdt" style="height: 20px;width: 20px;" />
          </div>
        </div>
    </div>
     
   
      
      <div class="ownwalletInfo" style="margin-top: 30px;">
        <div class="ownTips">{{$t("locked.direct")}}</div>
      </div>

      <Divider style="background: rgba(142, 200, 243, 0.5)" />
      
      <div  v-for="(item,index) in getUserGenerateslist" :key="index" >
        <div v-if="item.buyTimestamp>0" class="StakeInfo">
          <div class="StakeResult" style="display: flex;align-items: center;line-height: 30px;top: 0;">
            <!-- <img  class="daimi" :src="swap_usdt" style="height: 20px;width: 20px;" /> -->
            · {{ formatTimestampToMinute(item.buyTimestamp*1000) }}</div>
          <div class="StakeMoney" style="display: flex;align-items: center;text-align: center;">
            {{item.rewardExtractedAmount | fildecimals}}/{{item.rewardAllAmount | fildecimals}}
            <img  class="daimi" :src="swap_usdt" style="height: 20px;width: 20px;" />
          </div>
        </div>
      </div>

      <Divider style="background: rgba(142, 200, 243, 0.1)" />
      <div class="WithDrawBtn">
        <router-link to="/EvWithdraw">
          <button class="tbnStyle2 ripple "  >
          {{$t("locked.Back")}}
        </button>
        </router-link>
        <button class="tbnStyle2 ripple" @click="giveProductDirectTokenUsers()">
          {{$t("locked.Aggregation")}}
        </button>
      </div>
    
    </div>

    <div><img class="bStyle" :src="stylea" alt="" /></div>
    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg" />
    </div>
    <!--loading end-->
    <div class="selectCoin" v-show="isShown2">
      <div class="tipsandclose">
        <div class="t1">{{ $t("swap.recommend") }}（Polygon）</div>
        <div class="closeimg"><img :src="swap_close" alt="" @click="isShown2 = !isShown2"></div>
      </div>
      <div class="allconin">
        <div class="coinBTN" @click="onselect(swap_usdt, 'USDT', 'USDT_swap', '6')">
          <img :src="swap_usdt" alt="">
          <div class="coiname">USDT</div>
        </div>
        <div class="coinBTN" @click="onselect(swap_usdc, 'USDC', 'USDC_swap', '6')">
          <img :src="swap_usdc" alt="">
          <div class="coiname">USDC</div>
        </div>
        <!-- <div class="coinBTN" @click="onselect(swap_dai, 'DAI', 'DAI_swap', '18')">
          <img :src="swap_dai" alt="">
          <div class="coiname">DAI</div>
        </div> -->
        <!-- <div class="coinBTN" @click="onselect(swap_matic, 'MATIC', 'MATIC', '18')">
                            <img :src="swap_matic" alt="">
                            <div class="coiname">MATIC</div>
                        </div> -->
        <!-- @click="onselect(swap_falcon,'FALCON','falcon_swap','18')" -->
        <!-- <div class="coinBTN" @click="onselect(swap_falcon,'FALCON','falcon_swap','18')">
                            <img :src="swap_falcon" alt="">
                            <div class="coiname">FALCON</div>
                        </div> -->

        <!-- @click="onselect(swap_dai, 'USDS', 'USDC_swap', '18')" -->
        <!-- <div class="coinBTN">
          <img :src="swap_dai" alt="">
          <div class="coiname">USDS</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style scoped src="@/assets/css/EvWithdraw.css"></style>
<style lang="less" scoped>
.WithDrawBtn {
  display: flex;
  justify-content: space-between;

  .tbnStyle3 {
    width: 134px;
    margin-bottom: 15px;
    height: 50px;
    line-height: 50px;
    position: relative;
    color: rgb(9, 0, 0);
    font-weight: bolder;
    top: 8px;
    border: none;
    outline: none;
    font-size: 20px;
    border-radius: 8px;
    padding: 0 10px;
    cursor: pointer;
    background-image: linear-gradient(126deg,
        rgba(209, 209, 209, 0.3),
        rgba(208, 208, 208, 0.3));
  }

}

.slider {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-button {
  width: 25px;
  height: 25px;
  line-height: 21px;
  background-color: rgb(172, 7, 114);
  color: #fff;
  border: 2px solid white;
  border-radius: 50px;
  text-align: center;
  font-size: 12px;
}

.tbnStyle2_div {
  background-color: rgb(3, 198, 101);
  color: #fff;
  width: 35px;
  height: 20px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  border-radius: 5px;
  margin-right: 5px;

}

.fanhui {
  display: flex;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 10px;
}

.fanhui img {
  width: 16px;
  height: 16px;
}



.nowNumber {
  text-align: center;
}
.ownwalletInfo{
  margin-bottom: 10px;
   .ownTips{
    font-size: 17px;    
   }
}
.endTime {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #08ebeb;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .prompt{
    color: #f1f1f1;
    font-size: 14px;
    margin-top: 20px;
  }
</style>
<script>
import swap_falcon from "@/assets/img/swap_falcon.svg";
import swap_close from "@/assets/img/swap_close.svg";
import swap_arrow from "@/assets/img/swap_arrow.svg";
import swap_speed from "@/assets/img/speed.svg";
import swap_crow from "@/assets/img/crow.svg";
import swap_dai from "@/assets/img/swap_dai.svg";
import swap_usdc from "@/assets/img/swap_usdc.svg";
import swap_usdt from "@/assets/img/swap_usdt.svg";
import lockedtime from "@/assets/img/lockedtime.svg";
import web3Utils from "@/utils/web3Utils.js";
import Marquee from "@/components/Marquee.vue";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/btnimg.png";
import contract from "@/plugins/contract.js";
import loadingimg from "@/assets/img/loading.svg";
import siteapi from "@/json/siteapi.json";
import sign from "@/plugins/sign.js";
import Vue from "vue";
import { Slider } from 'vant';
import aaveChild from "@/plugins/aaveChild";

Vue.use(Slider);
import Plugens from "@/plugins/index";

export default {
  name: "EvWithdraw",
  data() {
    return {
      swap_falcon,
      swap_close,
      swap_arrow,
      swap_dai,
      swap_crow,
      lockedtime,
      swap_speed,
      swap_usdc,
      swap_usdt,
      dai: dai,
      daimni: daimni,
      daiValue: 0,
      daiOwnMoney: 0,
      daiShareMoney: 0,
      daiTemeMoney: 0.0,
      daiTemeMoney2: 0,
      stylea: stylea,
      usersdata: {},
      typeextract: 0,
      typeReinvestment: 0,
      typeReceive: 0,
      loadingimg: loadingimg,
      isloading: false,
      swapimg2: "",
      swapname: "",
      swap_decimalsA: 18,
      isShown2: false,
      swap_Aname: "",
      swap_aimg: "",
      swap_Aaddress: "",
      tokenUsersinvestAmount: 0,
      show: 1,
      slidervalue: 1,
      burnGetMyUserBurnInterestvalue1: 0,
      burnGetMyUserBurnInterestvalue2: 0,
      burnGetMyUserBurnInterestvalue3: 0,
      burnGetMyUserBurnInterestvalue4: 0,
      burnLevel: 0,
      burnLeveltype: 0,
      daiOwnMoneyjh: 0,
      falconAmount: 0,
      daiValue2: 0,
      burnContractAddressvalue: "",
      falconshow: false,
      typeApprove: 0,
      typeBurn: 0,
      sliderdisabled: false,
      burnUsersvalue: 0,
      burnAmount: 0,
      countdown: '',
      countdown1: '',
      timer: null,
      timershow: false,
      finishshow: false,
      timershow1: false,
      finishshow1: false,
      endTime:0,
      endTime1:0,
      firingtype: true,
      daiValue2small: 0,
      lastcalltimevalue: 0,
      lastcalltimevalue1: 0,
      lastExtractTime: 0,
      getWithdrawFalconTokenfalconAmount: 0,
      tokenUsersFInvestAmount: 0,
      burnSecond:0,
      getMyUserBurnDayInterestdata:0,
      lpBurnDayUsersinvestAmountIsBurn:false,
      toptypeshow:true,
      usdtinvestAmount:0,
      DAIinvestAmount:0,
      falconTokenUsersRewardAmount: 0,
      lockTimestamp:0,
      getDepositDatadata:[
        0,0,0,
      ],
      getFixedUserDepositslist:[],
      getUserGenerateslist:[]

    };
  },
  components: {
    Marquee,

  },
  filters: {

    fildecimals(e) {
      const multipliedNumber = e * 10000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 10000;
      //    console.log(result);

      let value = parseFloat(result);
      return value;
    },

  },
  async mounted() {
    // Determine whether the wallet is connected
    if (typeof window.ethereum !== 'undefined') {
      //console.log("Connected wallet");

      // Asynchronously obtaining network information
      window.ethereum
        .request({ method: 'eth_chainId' })
        .then((chainId) => {
          // Determine whether the current network is a Polygon chain
          if (chainId === '0x38') {
            //console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //console.log("The current network is not Polygon chain");
            this.$router.push({ path: '/' });
            return
          }
        })
        .catch((error) => {
          //console.error('Failed to obtain network information. Procedure:', error);
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi
    const currentAddress = await web3Utils.connectWallet();
    //console.log(currentAddress)
    this.addresscode = currentAddress
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(currentAddress)
      // );

      // console.log(this.daiOwnMoney.toFixed(6));
      this.signtype()
      // this.exchangeallowance()
    }
  },
  methods: {
    funlastcalltime() {
      const now = Date.now();
      const date = this.lastcalltimevalue * 1 + this.lastExtractTime * 1000

      console.log(date, '22222222222222222222', this.lastExtractTime, this.lastcalltimevalue)
      // 用法示例

      const storedEndTime = date
      console.log(storedEndTime, 'aaaaaaaaaaaaa')
      // localStorage.getItem('endTime');
      if (storedEndTime) {
        this.endTime = parseInt(storedEndTime, 10);
      } else {
        this.startCountdown(1 * 24 * 60 * 60); // 5天倒计时
      }
      this.updateCountdown(date);
      // setInterval(this.updateCountdown, 1000);
      this.timer = setInterval(() => {
        //需要定时执行的代码
        this.updateCountdown(date)
      }, 1000)
    },
    funlastcalltime1() {
      const now = Date.now();
      const date = this.lastcalltimevalue1 * 1 + this.lastExtractTime * 1000

      console.log(date, '22222222222222222222', this.lastExtractTime, this.lastcalltimevalue1)
      // 用法示例

      const storedEndTime = date
      console.log(storedEndTime, 'aaaaaaaaaaaaa')
      // localStorage.getItem('endTime');
      if (storedEndTime) {
        this.endTime1 = parseInt(storedEndTime, 10);
      } else {
        this.startCountdown1(1 * 24 * 60 * 60); // 5天倒计时
      }
      this.updateCountdown1(date);
      // setInterval(this.updateCountdown, 1000);
      this.timer1 = setInterval(() => {
        //需要定时执行的代码
        this.updateCountdown1(date)
      }, 1000)
    },
    startCountdown(duration) {
      this.endTime = Date.now() + duration * 1000;
      localStorage.setItem('endTime', this.endTime);
    },
    startCountdown1(duration) {
      this.endTime1 = Date.now() + duration * 1000;
      localStorage.setItem('endTime1', this.endTime1);
    },
    updateCountdown(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime - now <= 0) {
          console.log('结束')
          this.lastcalltimevalue = 0
          this.finishshow = true
          clearInterval(this.timer)
        }
      }
      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if (this.pad(hours) == null) {
        this.countdown = 0
        return
      }

      this.countdown = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    updateCountdown1(item) {
      const now = Date.now();
      console.log(now,'111111111111111111111111')
      var remainingTime
      remainingTime = Math.max((item - now) / 1000, 0);
      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      console.log(days,'daysdaysdays')
      var itemvalue = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
      return itemvalue
    },
    
 formatTimestampToMinute(timestamp) {
    var date = new Date(timestamp);
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // 月份是从0开始的
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
 
    // 如果需要前导零，可以使用以下代码
    month = ('0' + month).slice(-2);
    day = ('0' + day).slice(-2);
    hours = ('0' + hours).slice(-2);
    minutes = ('0' + minutes).slice(-2);
 
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
},
    updateCountdown1type(item) {
      const now = Date.now();
      console.log(now,'111111111111111111111111')
    var daats=true
      if (now > item) {
         console.log('结束')
         daats=false
        }else{
          daats=true
        }

      return daats
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {
          console.log(res, '签名验证')
          if (res.code == 0) {

              this.getDepositData()
              this.getProductTokenUsers()
              this.getProductDirectTokenUsers()
          }
          this.isloading = false;
        });
    },



    async getDepositData() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .getDepositData(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          this.sitedata.USDT_swap.address
        )
        .then((res) => {
          console.log(res,'getDepositData')
       this.getDepositDatadata=res.data
        });
    },
    async getProductTokenUsers() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .getProductTokenUsers(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
        )
        .then((res) => {
          console.log(res,'getProductTokenUsers')
          this.getFixedUserDepositslist=res.data
      //  this.getDepositDatadata=res.data
        });
    },
    async getProductDirectTokenUsers() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .getProductDirectTokenUsers(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
        )
        .then((res) => {
          console.log(res,'getProductDirectTokenUsers')
          this.getUserGenerateslist=res.data
        });
    },
    async giveProductDirectTokenUsers() {
      this.isloading = true;
      let Address;
      Address = this.sitedata.aave_token.address;
      try {
        await contract.giveProductDirectTokenUsers(
          Address, //Contract address
          this.addresscode,//Current address
        ).then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")
            });
            this.signtype();
          } else {
            // this.typeApprove = 1;
            this.isloading = false;
            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }

        });
      } catch (error) {
        this.isloading = false;
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },












    warning(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.withdraw"),
      });
    },
    warning2(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Reinvest"),
      });
    },
    warning3(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Getreward"),
      });
    },
  },

  computed: {

    formattedfInvestAmount() {
      if (!this.tokenUsersFInvestAmount) return "0.0000";
      const multipliedNumber = this.tokenUsersFInvestAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },

    formattedDaiOwnMoney2() {
      if (!this.tokenUsersinvestAmount) return "0.0000";
      const multipliedNumber = this.tokenUsersinvestAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.00";
      const multipliedNumber = this.daiOwnMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      //console.log(this.daiOwnMoney)
      let value = parseFloat(result);
      return value
    },
    formattedShareMoney() {
      if (!this.daiShareMoney) return "0.00";
      const multipliedNumber = this.daiShareMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
    formattedTemeMoney() {
      if (!this.daiTemeMoney) return "0.00";
      const multipliedNumber = this.daiTemeMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
    formattedTemeMoney2() {
      if (!this.daiTemeMoney2) return "0.00";
      const multipliedNumber = this.daiTemeMoney2 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
    formattedDaiOwnMoney3() {
      if (!this.daiOwnMoneyjh) return "0.0000";
      const multipliedNumber = this.daiOwnMoneyjh * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;

      let value = parseFloat(result);
      return value
    },
    burnFalconAmount() {
      if (!this.falconAmount) return "0.0000";
      const multipliedNumber = this.falconAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      let value = parseFloat(result);
      return value
    },


    formattedDaiOwnMoney4() {
      if (!this.burnGetMyUserBurnInterestvalue1) return "0.000";

      const multipliedNumber = this.burnGetMyUserBurnInterestvalue1 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;

      let value = parseFloat(result);


      return value
    },
    formattedDaiOwnMoney5() {
      if (!this.burnGetMyUserBurnInterestvalue2) return "0.000";
      const multipliedNumber = this.burnGetMyUserBurnInterestvalue2 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      let value = parseFloat(result);
      return value
    },
    formattedDaiOwnMoney7() {
      if (!this.burnGetMyUserBurnInterestvalue4) return "0.000";
      const multipliedNumber = this.burnGetMyUserBurnInterestvalue4 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      let value = parseFloat(result);
      return value
    },
  },
};
</script>
